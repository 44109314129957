<template>
  <validation-observer ref="observer" slim v-slot="{ handleSubmit }">
    <v-dialog :value="value" transition="slide-y-transition" class="elevation-0" persistent max-width="450px">
      <v-card :disabled="loading" :loading="loading">
        <v-card-title class="grey lighten-4">
          {{ $t("hr.training.request_cancellation_process") }}
          <v-spacer />
          <v-btn depressed icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <validation-provider
                slim
                :name="$t('hr.training.cancellation_note')"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="note"
                  :disabled="loading"
                  :label="$t('hr.training.cancellation_note')"
                  color="tertiary"
                  :counter="512"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-3">
          <v-spacer />
          <v-btn color="primary" depressed @click="handleSubmit(cancelRequest)" :disabled="loading">
            {{ $t("hr.training.cancel_request") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </validation-observer>
</template>

<script>
  export default {
    name: "RequestCancelDialog",
    props: {
      value: Boolean,
      requestId: String
    },
    data: () => ({
      loading: false,
      note: null
    }),
    methods: {
      closeDialog() {
        this.note = null;
        this.$refs.observer.reset();
        this.$emit("input", false);
      },
      cancelRequest() {
        const payload = { abolishReason: this.note };
        this.loading = true;
        this.$api.trainingRequestService
          .cancel(this.requestId, payload)
          .then(response => {
            if (response.status === 200) {
              this.$emit("success");
              this.closeDialog();
            }
          })
          .catch(console.error)
          .finally(() => (this.loading = false));
      }
    }
  };
</script>
